// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-contato-index-jsx": () => import("./../../../src/pages/contato/index.jsx" /* webpackChunkName: "component---src-pages-contato-index-jsx" */),
  "component---src-pages-estamparia-digital-index-jsx": () => import("./../../../src/pages/estamparia-digital/index.jsx" /* webpackChunkName: "component---src-pages-estamparia-digital-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-orcamento-index-jsx": () => import("./../../../src/pages/orcamento/index.jsx" /* webpackChunkName: "component---src-pages-orcamento-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-sobre-nos-index-jsx": () => import("./../../../src/pages/sobre-nos/index.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-index-jsx" */)
}

